




















































































































































import ViewInfiniteCreateNavigation from "./createCardsNavigation";
export default ViewInfiniteCreateNavigation;
