


























































import { Component, Prop, Vue } from "vue-property-decorator";
import CardsRulesItem from "./CardsRules";

@Component
export default class RoleInfo extends Vue {
  private name: string = "RoleInfo";
  CardsRulesItem = CardsRulesItem;

  @Prop() dataForm!: any;
}
