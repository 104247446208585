import Vue from 'vue';
import Component from 'vue-class-component';
import { VueCropper } from 'vue-cropper';
import RoleInfo from './RoleInfo.vue'
import CardsRulesItem from './CardsRules';
import AttributeInfo from './AttributeInfo.vue';
import RoleSkill from './RoleSkill.vue';

@Component({
  components: {
    VueCropper,
    RoleInfo,
    AttributeInfo,
    RoleSkill
  }
})
export default class ViewInfiniteCreateNavigation extends Vue {
  stepIndex = 1;
  valid = false;
  files: any;
  attributeCountMax = 480;
  CardsRulesItem = CardsRulesItem;

  form = {
    code: '',
    name: '',
    times: '',
    age: '',
    sex: '',
    picture: '',
    STR: 20,
    CON: 20,
    SIZ: 20,
    DEX: 20,
    APP: 20,
    INT: 20,
    POW: 20,
    EDU: 20,
    Luck: 5,
    skills: ''
  }

  model = false;
  modelSrc = '';
  crap = false;
  previews = {};
  option = {
    img: '',
    size: 1,
    full: true,
    outputType: 'png',
    canMove: true,
    fixedBox: false,
    original: false,
    canMoveBox: true,
    autoCrop: true,
    // 只有自动截图开启 宽度高度才生效
    autoCropWidth: 200,
    autoCropHeight: 200,
    centerBox: true,
    high: true,
    max: 2000,
    enlarge: 1,
    infoTrue: false,

  };
  show = true;
  fixed = true;
  fixedNumber = [10, 10];

  finish(type: string) {
    const _this = this;
    if (type === 'blob') {
      (this.$refs as any).cropper.getCropBlob((data: any) => {
        const img = window.URL.createObjectURL(data)
        _this.model = true;
        _this.modelSrc = img;
        _this.form.picture = img;
      })
    } else {
      (this.$refs as any).cropper.getCropData((data: any) => {
        _this.model = true;
        _this.modelSrc = data;
        _this.form.picture = data;
      })
    }
  }

  changeFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.option.img = e.target.result;
    }
    reader.readAsDataURL(this.files);
  }

  // 实时预览函数
  realTime(data: {}) {
    this.previews = data;
  }


  down(type: string) {
    // event.preventDefault()
    const aLink = document.createElement('a')
    aLink.download = 'demo'

  }

  pictureCheck() {
    this.finish('base64');
    this.stepIndex = 3;
    console.log(this.form);
  }

  test() {
    console.log(this.form);

  }

}