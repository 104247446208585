var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{attrs:{"vertical":"","non-linear":""},model:{value:(_vm.stepIndex),callback:function ($$v) {_vm.stepIndex=$$v},expression:"stepIndex"}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-stepper-step',{attrs:{"complete":_vm.stepIndex > 1,"step":"1","editable":""}},[_vm._v(" 录入角色基础数据 "),_c('small')]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-5","height":"100%"}},[_c('RoleInfo',{attrs:{"data-form":_vm.form}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepIndex = 2;
          _vm.test();}}},[_vm._v(" 下一步 ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepIndex = 4}}},[_vm._v(" 上一步 ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepIndex > 2,"step":"2","editable":""}},[_vm._v(" 选择角色图片形象 ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-5"}},[_c('v-card-text',[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","counter":"","label":"File input","placeholder":"Select your files","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},on:{"change":function($event){return _vm.changeFile()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var index = ref.index;
          var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.files.length - 2)+" File(s) ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('v-row',[_c('v-col',[_vm._v(" 剪裁区域: 滑轮可以缩放 "),_c('div',{staticClass:"cut"},[_c('vue-cropper',{ref:"cropper",attrs:{"img":_vm.option.img,"output-size":_vm.option.size,"output-type":_vm.option.outputType,"info":true,"full":_vm.option.full,"fixed":_vm.fixed,"fixed-number":_vm.fixedNumber,"can-move":_vm.option.canMove,"can-move-box":_vm.option.canMoveBox,"fixed-box":_vm.option.fixedBox,"original":_vm.option.original,"auto-crop":_vm.option.autoCrop,"auto-crop-width":_vm.option.autoCropWidth,"auto-crop-height":_vm.option.autoCropHeight,"center-box":_vm.option.centerBox,"high":_vm.option.high,"mode":"cover","max-img-size":_vm.option.max},on:{"real-time":_vm.realTime}})],1)]),_c('v-col',[_vm._v(" 剪裁预览: (固定尺寸比例 1:1) "),_c('div',{staticClass:"show-preview",style:({
                  width: _vm.previews.w + 'px',
                  height: _vm.previews.h + 'px',
                  overflow: 'hidden',
                  margin: '5px',
                })},[_c('div',{style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])])])],1)],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.pictureCheck()}}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepIndex--}}},[_vm._v(" 上一步 ")])],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepIndex > 3,"step":"3","editable":""}},[_vm._v(" 分配人物属性 ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-4"}},[_c('v-card-text',[_c('AttributeInfo',{attrs:{"data-form":_vm.form}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepIndex = 4;
          _vm.test();}}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepIndex--}}},[_vm._v(" 上一步 ")])],1),_c('v-stepper-step',{attrs:{"step":"4","editable":""}},[_vm._v(" 分配人物技能 ")]),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-card',{staticClass:"mb-12",attrs:{"color":"grey lighten-5","height":"200px"}},[_c('v-card-text',[_c('RoleSkill',{attrs:{"data-form":_vm.form}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepIndex = 1}}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.stepIndex--}}},[_vm._v(" 上一步 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }