


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class RoleSkill extends Vue {
  private name: string = "RoleSkill";

  @Prop() dataForm!: any;

  basePointsMax = 160;
  skillPointsMax = 80 + this.basePointsMax;

  @Watch("dataForm.EDU")
  changeEdu() {
    this.skillPointsMax = this.dataForm.EDU * 4 + this.basePointsMax;
  }

  


}
