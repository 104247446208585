


















































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AttributeDescribe from "./AttributeDescribe";
import CardsRulesItem from "./CardsRules";

@Component
export default class AttributeInfo extends Vue {
  private name: string = "AttributeInfo";
  CardsRulesItem = CardsRulesItem;
  attributeCountMax = 480;
  attributeDescribe = {
    STR: "手无缚鸡之力",
    CON: "体弱多病",
    SIZ: "孩童，身短体瘦",
    DEX: "安了假腿",
    APP: "用脸就能恐惧敌人或队友",
    INT: "脑子是个好东西，可惜。。。",
    POW: "尔不过玩物",
    EDU: "目不识丁",
  };

  @Prop() dataForm!: any;

  @Watch("dataForm.STR")
  getSTRDescribe(value: number) {
    this.attributeDescribe.STR = AttributeDescribe.getSTR(value);
  }

  @Watch("dataForm.CON")
  getCONDescribe(value: number) {
    this.attributeDescribe.CON = AttributeDescribe.getCON(value);
  }
  @Watch("dataForm.SIZ")
  getSIZDescribe(value: number) {
    this.attributeDescribe.SIZ = AttributeDescribe.getSIZ(value);
  }
  @Watch("dataForm.DEX")
  getDEXDescribe(value: number) {
    this.attributeDescribe.DEX = AttributeDescribe.getDEX(value);
  }
  @Watch("dataForm.APP")
  getAPPDescribe(value: number) {
    this.attributeDescribe.APP = AttributeDescribe.getAPP(value);
  }
  @Watch("dataForm.INT")
  getINTDescribe(value: number) {
    this.attributeDescribe.INT = AttributeDescribe.getINT(value);
  }

  @Watch("dataForm.POW")
  getPOWDescribe(value: number) {
    this.attributeDescribe.POW = AttributeDescribe.getPOW(value);
  }
  @Watch("dataForm.EDU")
  getEDUDescribe(value: number) {
    this.attributeDescribe.EDU = AttributeDescribe.getEDU(value);
  }

  attributeRules = [
    () =>
      this.attributeCountMax -
        this.dataForm.STR -
        this.dataForm.CON -
        this.dataForm.SIZ -
        this.dataForm.DEX -
        this.dataForm.APP -
        this.dataForm.INT -
        this.dataForm.POW -
        this.dataForm.EDU >=
        0 || "总计数值不正确",
  ];
}
