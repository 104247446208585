
export default class AttributeDescribe {

  static getSTR(value: number): string {
    if (value <= 15) {
      return '穿衣服都费劲';
    }
    if (value <= 20) {
      return '手无缚鸡之力';
    }
    if (value <= 60) {
      return '有正常人力量';
    }
    if (value <= 80) {
      return '超乎常人的力度'
    }
    return '错误数值';
  }

  static getCON(value: number) {
    if (value <= 20) {
      return '常年患病在身';
    }
    if (value <= 40) {
      return '体弱多病';
    }
    if (value <= 60) {
      return '不会生什么大毛病';
    }
    if (value <= 80) {
      return '健硕，浑身湿透也不会感冒';
    }
    if (value < 100) {
      return '不懂疼痛为何物';
    }
    return '错误数值';
  }

  static getSIZ(value: number) {
    if (value <= 20) {
      return '体型像孩童,身短体瘦';
    }
    if (value <= 40) {
      return '小学生身高';
    }
    if (value <= 60) {
      return '普遍身高155-175';
    }
    if (value <= 80) {
      return '不是高就是胖';
    }
    if (value <= 100) {
      return '肥壮';
    }
    return '错误数值';
  }

  static getDEX(value: number) {
    if (value <= 20) {
      return '安了假腿';
    }
    if (value <= 40) {
      return '很不灵活';
    }
    if (value <= 60) {
      return '中规中矩';
    }
    if (value <= 80) {
      return '是一位运动健将';
    }
    if (value < 100) {
      return '跑得比香港记者还快';
    }
    return '错误数值';
  }

  static getAPP(value: number) {
    if (value <= 20) {
      return '用脸就能恐惧敌人或队友';
    }
    if (value <= 40) {
      return '和大便比起来，还能看的过去';
    }
    if (value <= 60) {
      return '人群之中谁也不会看你一眼之后就忘不掉你容颜';
    }
    if (value <= 80) {
      return '五官端正，仪表堂堂';
    }
    if (value < 100) {
      return '沉鱼落雁，闭月羞花';
    }
    return '错误数值';
  }

  static getINT(value: number) {
    if (value <= 20) {
      return '脑子是个好东西，可惜。。。';
    }
    if (value <= 40) {
      return '宛如智障';
    }
    if (value <= 60) {
      return '有着普通人的灵光一现';
    }
    if (value <= 80) {
      return '可以自主进行发明创造';
    }
    if (value < 100) {
      return '天才级水准';
    }
    return '错误数值';
  }

  static getPOW(value: number) {
    if (value <= 20) {
      return '尔不过玩物';
    }
    if (value <= 40) {
      return '痴愚盲目';
    }
    if (value <= 60) {
      return '如常人一般会有一定自制力';
    }
    if (value <= 80) {
      return '我心如铁，心坚石穿';
    }
    if (value < 100) {
      return '泰山崩于面而色不变';
    }
    return '错误数值';
  }

 static getEDU(value: number) {
    if (value <= 20) {
      return '目不识丁';
    }
    if (value <= 40) {
      return '小学毕业';
    }
    if (value <= 60) {
      return '高中毕业';
    }
    if (value <= 80) {
      return '是重点大学的学生，或是普通大学的研究生';
    }
    if (value < 100) {
      return '饱读诗书，满腹经纶';
    }
    return '错误数值';
  }
  
  static getLuck(value: number) {
    if (value <= 20) {
      return '克夫克妻';
    }
    if (value <= 40) {
      return '霉运连连';
    }
    if (value <= 60) {
      return '命格平庸';
    }
    if (value <= 80) {
      return '在马路边捡到100块';
    }
    if (value < 100) {
      return '会被彩票店拒之门外';
    }
    return '错误数值';
  }





}
