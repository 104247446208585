export default class CardsRulesItem {


  static nameRules = [
    (v: any) => !!v || "Name is required",
    (v: string | any[]) => v.length <= 10 || "Name must be less than 10 characters",
  ];

  static numberRules = [
    (v: any) => !!v || "this is required",
    (v: number) => (v >= 0 && v <= 99999) || "Number has to be between 0 and 99999",
  ];


}